import React, { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { deleteCookie } from "../../common-service/CommonService";
import { StoreContext } from "../../context/StoreContext";
import BannerAd from "../../../component/_common/banner-ad/BannerAd";
import OwlCarousel from "../../../component/_common/owl-carousel/OwlCarousel";
import { constants } from "../../../core/constants/constants";

const ViewContainer = ({ children }) => {
  let navigate = useNavigate();
  const { user, isLoggedIn } = useContext(StoreContext);

  const onLogout = (e) => {
    e.preventDefault();
    if (isLoggedIn) {
      sessionStorage.clear();
      deleteCookie("token");
      window.location.reload();
    } else {
      navigate("/login");
    }
  };

  const goToPage = (path) => (e) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <Fragment>
      {/* <OwlCarousel className="owl-carousel owl-theme" data={constants.ads} autoplay={true} autoplayTimeout={4000} items={1}>
                {constants.ads.map((item, index) => (
                    <div className='item' key={index}>
                        <BannerAd src={item.src} href={item.href} />
                    </div>
                ))}
            </OwlCarousel> */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/#">
            Restaurant Menu
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {user?.id && !user?.is_admin && (
                <>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      aria-current="page"
                      href="/profile"
                      onClick={goToPage("/profile")}
                    >
                      Profile
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/#"
                      onClick={goToPage(
                        "/menu/" + user?.nameEn?.replaceAll(" ", "_")
                      )}
                    >
                      Menu
                    </a>
                  </li>
                </>
              )}
              {user?.is_admin == true && (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    aria-current="page"
                    href="/dashboard"
                    onClick={goToPage("/dashboard")}
                  >
                    Dashboard
                  </a>
                </li>
              )}
              <li className="nav-item">
                <a className="nav-link" href="/#" onClick={onLogout}>
                  {isLoggedIn ? "Logout" : "Login"}
                </a>
              </li>
              {/* <li className="nav-item">
                                <a className="nav-link" href="/#">Items</a>
                            </li> */}
              {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><a className="dropdown-item" href="/#">Action</a></li>
                                    <li><a className="dropdown-item" href="/#">Another action</a></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item" href="/#">Something else here</a></li>
                                </ul>
                            </li> */}
            </ul>
          </div>
        </div>
      </nav>
      {children}
    </Fragment>
  );
};

export default ViewContainer;
