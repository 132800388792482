import axios from 'axios'

export const BASE_URL = window.location.host.startsWith('localhost') ? 'http://localhost:8000/' : 'https://restaurantmenuapi.documentslb.com/'
export const MEDIA_URL = BASE_URL.slice(0, -1)

axios.defaults.baseURL = BASE_URL + 'api/'
axios.defaults.timeout = 30000

export function setDefaultHeader(value) {
    axios.defaults.headers.common['Authorization'] = value
}

export function postCheckToken(data) {
    return axios.post('checkToken', data)
}
export function postLogin(data) {
    return axios.post('login', data)
}
export function postSignup(data) {
    return axios.post('signup', data)
}
export function postGetAllRestaurants(data) {
    return axios.post('getAllRestaurants', data)
}
export function postGetRestaurantById(data) {
    return axios.post('getRestaurantById', data)
}
export function postUpSertRestaurant(data) {
    return axios.post('upSertRestaurant', data)
}
export function postGetAllCategories(data) {
    return axios.post('getAllCategories', data)
}
export function postUpSertCategory(data) {
    return axios.post('upSertCategory', data)
}
export function postGetAllItemsByCategoryId(data) {
    return axios.post('getAllItemsByCategoryId', data)
}
export function postUpSertItem(data) {
    return axios.post('upSertItem', data)
}
export function postGetAllCurrencies(data) {
    return axios.post('getAllCurrencies', data)
}
