import React, { useContext, useEffect } from 'react'
import { Route, Routes, BrowserRouter, useNavigate } from 'react-router-dom';
import AOS from "aos";
import './App.css'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "aos/dist/aos.css";
import NotFound from './component/not-found/NotFound'
import { postCheckToken, setDefaultHeader } from './core/api/Api'
import { deleteCookie, getCookie } from './core/common-service/CommonService'
import { RouteContext } from './core/context/RouteContext'
import { StoreContext } from './core/context/StoreContext'
import ProtectedRoutes from './core/routes/protected-routes/ProtectedRoutes'
import ViewContainer from './core/routes/view-container/ViewContainer';

const App = () => {

  const PreLoader = () => {
    let navigate = useNavigate()
    const { setIsLoggedIn, setUser } = useContext(StoreContext)

    const checkToken = () => {
      const token = sessionStorage.getItem('token') || getCookie('token')
      if (token) {
        setDefaultHeader(token)
        postCheckToken().then(res => {
          const response = res.data
          if (response?.success) {
            const user = response?.success?.user
            if (user) {
              setIsLoggedIn(true)
              setUser(user)
            } else {
              alert("User not allowed")
            }
          } else {
            setIsLoggedIn(false)
            sessionStorage.clear()
            deleteCookie('token')
            navigate('/login')
          }
        }).catch(e => {
          setIsLoggedIn(false)
          sessionStorage.clear()
          deleteCookie('token');
          navigate('/login')
        })
      } else {
        setIsLoggedIn(false)
      }
    }

    useEffect(() => {
      AOS.init();
      AOS.refresh();
      checkToken()
      return () => {
      };
    }, []);

    return (
      <></>
    )
  }

  const { paths } = useContext(RouteContext)
  return (
    <BrowserRouter>
      <PreLoader />
      <ViewContainer>
        <Routes>
          {paths.map((item, index) => (
            item.path.map((el) => (
              <Route key={index} path={el + (item.params.join(""))} element={item.protected ?
                <ProtectedRoutes>{item.component}</ProtectedRoutes>
                : item.component}
              />
            ))
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ViewContainer>
    </BrowserRouter>
  )
}

export default App