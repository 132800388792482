import React, { lazy, createContext, useState, } from "react";
const Dashboard = lazy(() => import("../../component/dashboard/Dashboard"));
const Login = lazy(() => import("../../component/login/Login"));
const Menu = lazy(() => import("../../component/menu/Menu"));
const Profile = lazy(() => import("../../component/profile/Profile"));

export const RouteContext = createContext()
const RouteProvider = ({ children }) => {
    const [paths, setPaths] = useState([
        {
            blacklisted_by: [],
            protected: false,
            path: ["/menu"],
            params: ["/:name"],
            component: <Menu />
        },
        {
            blacklisted_by: [],
            protected: false,
            path: ["/login"],
            params: [],
            component: <Login />
        },
        {
            blacklisted_by: [],
            protected: true,
            path: ["/profile"],
            params: [],
            component: <Profile />
        },
        {
            blacklisted_by: [],
            protected: true,
            path: ["/dashboard"],
            params: [],
            component: <Dashboard />
        },
    ])

    return (
        <RouteContext.Provider value={{
            paths, setPaths,
        }}>
            {children}
        </RouteContext.Provider>
    )
}
export default RouteProvider